.icon-arrow-down {
  width: 1.75em;
  height: 1em;
  fill: #fff;
}
.icon-arrow-left {
  width: 3.27em;
  height: 1em;
  fill: #fff;
}
.icon-arrow-prev {
  width: 0.57em;
  height: 1em;
  fill: #5f6369;
}
.icon-arrow-right {
  width: 3.27em;
  height: 1em;
  fill: #fff;
}
.icon-fb {
  width: 1em;
  height: 1em;
  fill: #5f6369;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: #e67e22;
}
.icon-twitter {
  width: 1em;
  height: 1em;
  fill: #5f6369;
}
.icon-vk {
  width: 1em;
  height: 1em;
  fill: #5f6369;
}
