@mixin w {
  @media only screen and (max-width: "1599px") {
    @content; } }
@mixin x {
  @media only screen and (max-width: "1439px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1279px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin a {
  @media only screen and (max-width: "639px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,700&display=swap&subset=cyrillic');
@mixin f {
  font-family: 'Comfortaa', cursive; }

$bl: #5f6369;
$wh: #ffffff;
$orange: #E67E22;
$blue: #308FCF;
$l-blue: #D5EFFA;
$grey: #CBCBCB;

.directory__title--main {
  margin-bottom: 20px; }

.company__data {
  margin-top: 20px;
  &--title {
    margin-bottom: 0 !important;
    font-size: 25px !important; }
  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1 em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px; }
  * + * {
    margin-top: 15px; } }

.description__options {
  h2 {
    font-weight: 400;
    font-size: 14px; } }

html {
  &.no-scroll {
    overflow: hidden; } }

body {
  min-width: 375px;
  @include f;
  // -webkit-font-smoothing: antialiased
  // -moz-osx-font-smoothing: grayscale
  font-size: 14px;
  line-height: 1.3;
  color: $bl;
  &.no-scroll {
    overflow: hidden; } }

button,
input,
textarea,
select {
  @include f; }

.ctr {
  max-width: 1476px;
  margin: 0 auto;
  padding: 0 30px;
  @include t {
    padding: 0 20px; }
  @include m {
    padding: 0 16px; } }

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
  &__content {
    flex-grow: 1; } }

a.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center; }

.btn {
  min-width: 194px;
  height: 39px;
  padding: 2px 15px 0;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 700;
  transition: all .25s;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  &_blue {
    background: #2F8ECD;
    color: $wh;
    &:hover {
      background: $orange; } }
  &_orange {
    background: $orange;
    color: $wh;
    &:hover {
      background: lighten($orange, 10); } }
  &_border {
    border: 1px solid #2F8ECD;
    color: #2F8ECD;
    &:hover {
      background: #2F8ECD;
      color: $wh; } } }

.header {
  position: relative;
  z-index: 10;
  padding: 70px 0 52px;
  @include x {
    padding: 50px 0; }
  @include d {
    padding: 30px 0; }
  @include m {
    margin-bottom: 20px;
    padding: 0; }
  &__ctr {
    display: flex;
    align-items: center;
    @include m {
      position: relative;
      z-index: 10;
      padding: 16px 15px;
      background: $wh; } }
  &__logo {
    margin: 0 auto 0 -10px;
    font-size: 0;
    @include m {
      position: absolute;
      top: 57%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 10; } }
  &__pic {
    width: 305px;
    @include t {
      width: 200px; }
    @include m {
      width: 150px; } }
  &__wrapper {
    @include m {
      display: none;
      position: absolute;
      top: 47px;
      left: 0;
      right: 0;
      z-index: 8;
      padding: 20px 15px 30px;
      border-radius: 0 0 20px 20px;
      background: $wh; }
    @media only screen and (min-width: "768px") {
      display: block!important; } }
  &__nav {
    display: flex;
    align-items: center;
    @include t {
      padding-right: 20px; }
    @include m {
      display: block;
      padding: 0; } }
  &__item {
    position: relative;
    min-width: 121px;
    @include t {
      min-width: auto; }
    @include nl {
      margin-right: 11px;
      @include r(1475) {
        margin-right: 20px; }
      @include t {
        margin-right: 15px; }
      @include m {
        margin: 0 0 10px; } } }
  &__head {
    display: block;
    padding: 0 22px;
    border-radius: 20px;
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 39px;
    color: $bl;
    cursor: pointer;
    transition: all .25s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include t {
      padding: 0 18px;
      line-height: 35px; }
    @include m {
      position: relative;
      padding: 0 20px;
      text-align: left; } }
  &__item.active &__head {
    border-radius: 20px 20px 0 0;
    background: $orange;
    color: $wh;
    @include m {
      background: #2F8ECD; } }
  &__body {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 222px;
    border: 1px dashed rgba($bl, .5);
    // padding: 10px 0 5px
    background: $wh;
    @include t {
      width: 190px; }
    @include m {
      position: relative;
      width: 100%;
      border: none;
      padding: 10px 0; } }
  &__box {
    position: relative; }
  &__top {
    position: relative;
    display: block;
    margin-left: -15px;
    padding: 0 18px 0 33px;
    border-radius: 20px 0 0 20px;
    font-size: 12px;
    font-weight: 700;
    line-height: 33px;
    color: $bl;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include t {
      line-height: 30px; }
    @include m {
      margin: 0;
      padding-left: 20px;
      border-radius: 17px;
      background: none;
      line-height: 34px;
      transition: all .25s; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: calc(100% - 1px);
      bottom: 0;
      width: 171px;
      border-radius: 0 20px 0 0;
      background: $orange;
      opacity: 0;
      transition: all .25s;
      @include t {
        width: 151px; }
      @include m {
        display: none; } } }
  &__box.active &__top {
    background: $orange;
    color: $wh;
    @include m {
      border-radius: 17px 17px 0 0;
      background: $blue; }
    &:before {
      opacity: 1; } }
  .js-header-item &__head,
  &__top {
    @include m {
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        width: 16px;
        height: 9px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 100%;
        transition: all .25s; }
      &:before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NTciIGhlaWdodD0iOS4xMjIiIHZpZXdCb3g9IjAgMCAxNS45NTcgOS4xMjIiPiAgPHBhdGggaWQ9Ikljb25faW9uaWMtaW9zLWFycm93LWJhY2siIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtYXJyb3ctYmFjayIgZD0iTTIuNzUsNy45NzYsOC43ODgsMS45NDNBMS4xNCwxLjE0LDAsMCwwLDcuMTcyLjMzM0wuMzMyLDcuMTY5QTEuMTM4LDEuMTM4LDAsMCwwLC4zLDguNzQxbDYuODY5LDYuODgzYTEuMTQsMS4xNCwwLDEsMCwxLjYxNS0xLjYxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA5LjEyMikgcm90YXRlKC05MCkiIGZpbGw9IiMzYzQwNDMiLz48L3N2Zz4=); }
      &:after {
        z-index: 2;
        opacity: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NTciIGhlaWdodD0iOS4xMjIiIHZpZXdCb3g9IjAgMCAxNS45NTcgOS4xMjIiPiAgPHBhdGggaWQ9Ikljb25faW9uaWMtaW9zLWFycm93LWJhY2siIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtYXJyb3ctYmFjayIgZD0iTTIuNzUsNy45NzYsOC43ODgsMS45NDNBMS4xNCwxLjE0LDAsMCwwLDcuMTcyLjMzM0wuMzMyLDcuMTY5QTEuMTM4LDEuMTM4LDAsMCwwLC4zLDguNzQxbDYuODY5LDYuODgzYTEuMTQsMS4xNCwwLDEsMCwxLjYxNS0xLjYxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA5LjEyMikgcm90YXRlKC05MCkiIGZpbGw9IiNmZmYiLz48L3N2Zz4=); } } }
  &__item.active &__head,
  &__box.active &__top {
    @include m {
      &:before,
      &:after {
        transform: translateY(-50%) rotate(180deg); }
      &:before {
        opacity: 0; }
      &:after {
        opacity: 1; } } }
  &__list {
    position: absolute;
    top: 100%;
    left: 100%;
    z-index: 2;
    width: 170px;
    border-width: 0 1px 1px;
    border-style: dashed;
    border-color: rgba($bl, .5);
    background: $wh;
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    @include t {
      top: 30px;
      width: 150px; }
    @media only screen and (min-width: "768px") {
      display: block!important;
      height: auto!important;
      overflow: visible!important; }
    @include m {
      position: static;
      width: 100%;
      padding-bottom: 5px;
      border: none;
      visibility: visible;
      opacity: 1;
      transition: none; } }
  &__box.active &__list {
    visibility: visible;
    opacity: 1; }
  &__list &__link {
    display: block;
    margin: 0 -11px;
    padding: 0 26px;
    border-radius: 20px;
    font-size: 12px;
    line-height: 33px;
    font-weight: 700;
    color: $bl;
    transition: all .25s;
    @include t {
      margin: 0;
      padding: 0 12px;
      line-height: 30px; }
    @include m {
      padding: 0 30px; } }
  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    background: rgba($bl, .42);
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    &.show {
      @include m {
        visibility: visible;
        opacity: 1; } } } }

body:not(.touch-device) .header__head:hover {
  background: $orange;
  color: $wh; }

body:not(.touch-device) .header__top:hover {
  background: $orange;
  color: $wh; }

body:not(.touch-device) .header__list .header__link:hover {
  background: $blue;
  color: $wh; }

.main {
  &_sm {
    margin-bottom: 48px;
    @include m {
      margin-bottom: 30px; } }
  &_big {
    margin-bottom: 160px;
    @include x {
      margin-bottom: 130px; }
    @include d {
      margin-bottom: 100px; }
    @include t {
      margin-bottom: 70px; }
    @include m {
      margin-bottom: 40px; } }
  &__container {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 45px;
    background: $l-blue;
    @include t {
      display: block;
      border-radius: 30px; }
    @include m {
      border-radius: 15px; } }
  &_sm &__container {
    padding: 57px 100px;
    @include x {
      padding: 50px 60px; }
    @include d {
      padding: 30px; }
    @include m {
      padding: 20px 15px; } }
  &_big &__container {
    justify-content: flex-end;
    min-height: 445px;
    padding: 50px 115px;
    @include x {
      padding: 40px 80px; }
    @include d {
      min-height: 350px;
      padding: 40px 50px; }
    @include t {
      min-height: auto; }
    @include m {
      padding: 20px 15px; } }
  &__preview {
    position: absolute;
    left: 120px;
    bottom: -50px;
    font-size: 0;
    @include x {
      left: 70px; }
    @include d {
      left: 30px; }
    @include t {
      left: auto;
      right: 20px;
      bottom: -10px; }
    @include m {
      display: none; } }
  &__pic {
    @include d {
      width: 400px; }
    @include t {
      width: 200px; } }
  &__desc {
    flex-shrink: 0;
    width: 520px;
    @include d {
      width: 450px; }
    @include m {
      width: 100%; } }
  &__title,
  &__info {
    font-size: 40px;
    line-height: 1.2;
    @include d {
      font-size: 32px; }
    @include m {
      font-size: 20px; } }
  &__title {
    font-weight: 700; }
  &__info {
    font-weight: 300; }
  &_big &__info {
    margin-bottom: 30px;
    @include m {
      margin-bottom: 15px; } }
  &__form {
    position: relative; }
  &_sm &__form {
    flex-shrink: 0;
    width: 425px;
    margin-left: auto;
    @include t {
      width: 100%;
      margin: 20px 0 0; }
    @include m {
      margin-top: 15px; } }
  &_big &__form {
    max-width: 425px;
    @include m {
      max-width: 100%; } }
  &__input {
    width: 100%;
    height: 47px;
    padding: 2px 20px 0 40px;
    border-radius: 24px;
    font-size: 13px;
    color: #3293D4;
    @include m {
      height: 36px;
      padding: 2px 45px 0 15px; }
    @include placeholder {
      color: #3293D4; } }
  &__btn {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 40px;
    padding: 1px 0 0 8px;
    font-size: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
      left: auto;
      right: 0;
      padding-left: 0; }
    .icon {
      font-size: 14px;
      fill: $orange;
      transition: opacity .25s; }
    &:hover .icon {
      opacity: 0.7; } } }

.title {
  position: relative;
  padding-left: 29px;
  font-size: 35px;
  line-height: (39/35);
  font-weight: 700;
  color: $bl;
  @include d {
    padding-left: 20px; }
  @include t {
    font-size: 28px; }
  @include m {
    padding-left: 15px;
    font-size: 18px; }
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $orange; } }

.library {
  margin-bottom: 120px;
  @include x {
    margin-bottom: 100px; }
  @include t {
    margin-bottom: 70px; }
  @include m {
    margin-bottom: 35px; }
  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include m {
      display: block;
      margin-bottom: 15px; } }
  &__title {
    margin-right: auto;
    @include m {
      margin: 0; } }
  &__indicators {
    display: flex;
    @include m {
      display: none; } }
  &__indicator {
    position: relative;
    padding-left: 17px;
    font-size: 14px;
    font-weight: 700;
    color: $grey;
    @include t {
      padding-left: 13px;
      font-size: 13px; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 7px;
      height: 7px;
      border-radius: 50%; }
    &_yes:before {
      background: $orange; }
    &_no:before {
      background: #EBEBEB; }
    @include nl {
      margin-right: 35px;
      @include t {
        margin-right: 20px; } } }
  &__container {
    overflow: hidden; }
  &__top {
    display: flex;
    align-items: flex-start;
    @include m {
      display: none; } }
  &__item {
    display: flex;
    @include m {
      display: block; }
    @include nl {
      @include m {
        margin-bottom: 15px; } } }
  &__head {
    flex-shrink: 0;
    width: 280px;
    padding: 0 20px 0 30px;
    @include d {
      width: 260px;
      padding: 0 20px; }
    @include m {
      display: block;
      position: relative;
      width: 100%;
      padding: 0 40px 0 15px;
      border-radius: 20px;
      transition: all .25s;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        width: 16px;
        height: 9px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 100%;
        transition: all .25s; }
      &:after {
        z-index: 2;
        opacity: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NTciIGhlaWdodD0iOS4xMjIiIHZpZXdCb3g9IjAgMCAxNS45NTcgOS4xMjIiPiAgPHBhdGggaWQ9Ikljb25faW9uaWMtaW9zLWFycm93LWJhY2siIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtYXJyb3ctYmFjayIgZD0iTTIuNzUsNy45NzYsOC43ODgsMS45NDNBMS4xNCwxLjE0LDAsMCwwLDcuMTcyLjMzM0wuMzMyLDcuMTY5QTEuMTM4LDEuMTM4LDAsMCwwLC4zLDguNzQxbDYuODY5LDYuODgzYTEuMTQsMS4xNCwwLDEsMCwxLjYxNS0xLjYxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA5LjEyMikgcm90YXRlKC05MCkiIGZpbGw9IiNmZmYiLz48L3N2Zz4=); } } }
  &__item.active &__head {
    @include m {
      background: $orange;
      border-radius: 20px 20px 0 0;
      color: $wh;
      &:before,
      &:after {
        transform: translateY(-50%) rotate(180deg); }
      &:before {
        opacity: 0; }
      &:after {
        opacity: 1; } } }
  &__item &__head {
    position: relative;
    z-index: 2;
    border-radius: 20px;
    font-size: 16px;
    line-height: 39px;
    font-weight: 700;
    color: darken($bl, 20);
    transition: all .25s;
    @include m {
      display: block;
      font-size: 15px; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      bottom: -1px;
      width: 20px;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTkgMzkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE5IDM5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTksMEwxOSwwTDAsMHYxOS41QzAsOC45LDguNCwwLjMsMTksMHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMCwxOS41VjM5aDE5djBDOC40LDM4LjcsMCwzMC4xLDAsMTkuNXoiLz48L3N2Zz4=) no-repeat 0 0 / 100% 100%;
      @include m {
        top: 50%;
        left: auto;
        right: 15px;
        bottom: auto;
        width: 16px;
        height: 9px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NTciIGhlaWdodD0iOS4xMjIiIHZpZXdCb3g9IjAgMCAxNS45NTcgOS4xMjIiPiAgPHBhdGggaWQ9Ikljb25faW9uaWMtaW9zLWFycm93LWJhY2siIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtYXJyb3ctYmFjayIgZD0iTTIuNzUsNy45NzYsOC43ODgsMS45NDNBMS4xNCwxLjE0LDAsMCwwLDcuMTcyLjMzM0wuMzMyLDcuMTY5QTEuMTM4LDEuMTM4LDAsMCwwLC4zLDguNzQxbDYuODY5LDYuODgzYTEuMTQsMS4xNCwwLDEsMCwxLjYxNS0xLjYxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA5LjEyMikgcm90YXRlKC05MCkiIGZpbGw9IiMzYzQwNDMiLz48L3N2Zz4=);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 100%; } } }
  &__top &__head {
    display: flex;
    padding-top: 12px; }
  &__body {
    display: flex;
    flex-grow: 1;
    @include m {
      height: 0;
      padding: 0 8px 0 5px;
      overflow: hidden;
      transition: height .25s; } }
  &__item &__body {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 18px;
      right: 19px;
      transform: translateY(-50%);
      width: auto;
      height: 1px;
      background-image: linear-gradient(90deg, #ebebeb 0, #ebebeb 33%, transparent 33%, transparent 100%);
      background-size: 8px 1px;
      @include m {
        display: none; } } }
  &__item.active &__body {
    @include m {
      height: 44px; } }
  &__cell {
    flex: 0 0 39px;
    width: 39px;
    text-align: center;
    @include m {
      flex: 0 0 9.0909%;
      width: 9.0909%; }
    @include nl {
      margin-right: auto; } }
  &__top &__cell {
    position: relative;
    padding: 12px 0 26px;
    font-size: 16px;
    font-weight: 700;
    color: darken($bl, 20);
    transition: color .25s;
    @include d {
      padding-bottom: 20px; }
    &:before {
      content: "";
      position: absolute;
      top: calc(100% + 18px);
      left: 50%;
      z-index: -2;
      transform: translateX(-50%);
      width: 1px;
      height: 5000px;
      background-image: linear-gradient(180deg, #ebebeb 0, #ebebeb 33%, transparent 33%, transparent 100%);
      background-size: 1px 8px; }
    &:after {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      right: -1px;
      z-index: 3;
      height: 21px;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9ItCh0LvQvtC5XzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzkgMTkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM5IDE5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzksMTlMMzksMTlWMEgxOS41QzMwLjEsMCwzOC43LDguNCwzOSwxOXoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTkuNSwwSDB2MTlsMCwwQzAuMyw4LjQsOC45LDAsMTkuNSwweiIvPjwvc3ZnPg==) no-repeat 0 0 / 100% 100%; }
    &.hide {
      pointer-events: none; } }
  &__number {
    position: relative;
    z-index: 3; }
  &__item &__cell {
    position: relative;
    display: block;
    height: 39px;
    font-size: 0;
    line-height: 39px;
    pointer-events: none;
    @include m {
      font-size: 18px;
      height: 44px;
      color: $bl; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 3;
      transform: translate(-50%,-50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #EBEBEB;
      transition: all .25s;
      pointer-events: none;
      @include m {
        top: auto;
        bottom: 0;
        transform: translate(-50%,0); } }
    &.show {
      pointer-events: all;
      &:before {
        background: $orange; } } }
  &__item:last-child &__cell {
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      z-index: -1;
      transform: translateX(-50%);
      width: 2px;
      background: $wh;
      height: 16px;
      @include m {
        display: none; } } }
  &__book {
    @include coverdiv;
    @include m {
      display: none; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      opacity: 0;
      transition: opacity .2s;
      pointer-events: none; }
    &:before {
      top: 0;
      right: 0;
      bottom: 0;
      width: 5000px;
      border-radius: 0 20px 20px 0;
      background: rgba($orange, .5); }
    &:after {
      left: 0;
      right: 0;
      bottom: 0;
      height: 5000px;
      border-radius: 0 0 20px 20px;
      background: rgba(#308FCF, .5); } }
  &__stage {
    font-size: 14px;
    font-weight: 700;
    color: #CBCBCB;
    @include nl {
      position: relative;
      margin-right: 21px;
      padding-right: 25px;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        background: $grey; } } } }

body:not(.touch-device) {}
body:not(.touch-device) .library__item .library__head:hover {
  background: $orange;
  color: $wh; }
body:not(.touch-device) .library__item .library__cell:hover:before {
  transform: translate(-50%,-50%) scale(3);
  background: $wh;
  @include m {
    transform: translate(-50%,0) scale(1);
    background: $orange; } }
body:not(.touch-device) .library__top .library__cell:hover {
  color: $orange; }
body:not(.touch-device) .library__book:hover {
  &:before,
  &:after {
    opacity: 1; } }

select {
  width: 100%;
  height: 40px;
  padding: 0 40px 0 15px;
  border-radius: 20px;
  border: 1px solid #2F8ECD;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: $wh;
  font-size: 14px;
  font-weight: 700;
  color: #2F8ECD; }

.nice-select.select {
  height: 40px;
  padding: 2px 35px 0 15px;
  border-radius: 20px;
  border-color: #2F8ECD;
  line-height: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #2F8ECD;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 16px;
    height: 9px;
    margin: 0;
    border: none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    transition: all .2s; }
  &:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NTciIGhlaWdodD0iOS4xMjIiIHZpZXdCb3g9IjAgMCAxNS45NTcgOS4xMjIiPiAgPHBhdGggaWQ9Ikljb25faW9uaWMtaW9zLWFycm93LWJhY2siIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtYXJyb3ctYmFjayIgZD0iTTIuNzUsNy45NzYsOC43ODgsMS45NDNBMS4xNCwxLjE0LDAsMCwwLDcuMTcyLjMzM0wuMzMyLDcuMTY5QTEuMTM4LDEuMTM4LDAsMCwwLC4zLDguNzQxbDYuODY5LDYuODgzYTEuMTQsMS4xNCwwLDEsMCwxLjYxNS0xLjYxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA5LjEyMikgcm90YXRlKC05MCkiIGZpbGw9IiMyZjhlY2QiLz48L3N2Zz4=); }
  &:after {
    z-index: 2;
    opacity: 0;
    // transform-origin: none
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NTciIGhlaWdodD0iOS4xMjIiIHZpZXdCb3g9IjAgMCAxNS45NTcgOS4xMjIiPiAgPHBhdGggaWQ9Ikljb25faW9uaWMtaW9zLWFycm93LWJhY2siIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtYXJyb3ctYmFjayIgZD0iTTIuNzUsNy45NzYsOC43ODgsMS45NDNBMS4xNCwxLjE0LDAsMCwwLDcuMTcyLjMzM0wuMzMyLDcuMTY5QTEuMTM4LDEuMTM4LDAsMCwwLC4zLDguNzQxbDYuODY5LDYuODgzYTEuMTQsMS4xNCwwLDEsMCwxLjYxNS0xLjYxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA5LjEyMikgcm90YXRlKC05MCkiIGZpbGw9IiNmZmYiLz48L3N2Zz4=); }
  &.open {
    background: #2F8ECD;
    border-radius: 20px 20px 0 0;
    color: $wh;
    &:before,
    &:after {
      transform: translateY(-50%) rotate(180deg); }
    &:before {
      opacity: 0; }
    &:after {
      opacity: 1;
      transform-origin: 50% 50%; } }
  .list {
    left: -1px!important;
    right: -1px!important;
    margin-top: 1px;
    border-radius: 0 0 20px 20px;
    box-shadow: none;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $blue;
    li {
      min-height: 33px;
      padding: 0 15px;
      font-weight: 400;
      line-height: 33px;
      color: $bl;
      &.selected {
        display: none; } } } }


.tabs {
  margin-bottom: 100px;
  @include t {
    margin-bottom: 80px; }
  @include m {
    margin-bottom: 40px; }
  &_sorting {
    margin-bottom: 25px;
    @include t {
      margin-bottom: 40px; } }
  &__head {
    position: relative;
    display: flex;
    margin-bottom: 25px;
    padding: 0 20px 13px 0;
    @include d {
      display: block; }
    @include m {
      margin: 0;
      padding: 0 10px 4px 0; }
    @include a {
      padding-left: 13px; }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 11px;
      border-radius: 6px;
      background: #2F8ECD;
      @include m {
        height: 4px; } } }
  &_sorting &__head {
    align-items: flex-end;
    margin-bottom: 65px;
    @include d {
      display: flex;
      margin-bottom: 40px; }
    @include t {
      margin-bottom: 25px; }
    @include m {
      display: block;
      margin-bottom: 15px;
      &:before {
        display: none; } }
    @include a {
      padding: 0; } }
  &_directory &__head {
    margin: 0 0 30px 285px;
    @include d {
      margin: 0 0 20px 0; }
    @include t {
      display: block;
      min-height: 91px;
      margin: 0 0 20px;
      padding: 0;
      &:before {
        display: none; } }
    @include m {
      min-height: 79px;
      margin-bottom: 20px; } }
  &_directory &__nav {
    @include t {
      display: none; } }
  &__title {
    margin-right: auto;
    margin-bottom: 15px;
    @include a {
      margin: 0 0 15px -13px; } }
  &__select {
    display: none;
    @include m {
      display: block;
      margin-bottom: 30px; } }
  &_directory &__select {
    @include t {
      display: block; }
    @include m {
      margin: 0; } }
  &_sorting &__title {
    margin: 0 105px 10px 0;
    @include t {
      margin-right: 50px; }
    @include m {
      margin: 0; }
    @include a {
      margin: 0; } }
  &_directory &__title {
    @include t {
      margin: 0 0 20px; }
    @include m {
      margin-bottom: 15px; }
    @include a {
      margin-bottom: 15px; } }
  &__line {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700; }
  &__all {
    margin: -10px 35px 0 0;
    color: $bl;
    @include m {
      margin-top: 0; }
    @include a {
      display: none; } }
  &__nav {
    display: flex;
    @include d {
      flex-grow: 1; } }
  &_sorting &__nav {
    flex-grow: 1;
    @include m {
      display: none; } }
  &__link {
    position: relative;
    padding: 12px 15px 21px;
    font-size: 16px;
    font-weight: 700;
    color: $bl;
    transition: color .25s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
      padding: 8px 8px 4px; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      bottom: -3px;
      z-index: -1;
      transform: translateX(-50%);
      width: 80px;
      background: url('/build/image/subtraction.png') no-repeat 0 0 / 100% 100%;
      opacity: 0;
      transition: opacity .25s;
      @include m {
        width: 50px; } }
    &:hover {
      color: $orange; }
    &.active {
      color: $wh;
      &:before {
        opacity: 1; } }
    @include nl {
      margin-right: 22px;
      @include x {
        margin-right: 10px; }
      @include d {
        margin-right: auto; } } }
  &_sorting &__link {
    padding: 10px 15px 18px;
    @include m {
      padding: 8px 8px 4px; }
    @include nl {
      margin-right: 68px;
      @include r(1475) {
        margin-right: 64px; }
      @include x {
        margin-right: 48px; }
      @include d {
        margin-right: 23px; }
      @include t {
        margin-right: 8px; } } }
  &__item {
    display: none;
    &:first-child {
      display: block; } }
  &__section {
    @include nl {
      margin-bottom: 25px;
      @include t {
        margin-bottom: 10px; }
      @include m {
        margin-bottom: 35px; } } }
  &__stage {
    display: inline-block;
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: 300;
    color: #2f3133;
    &:hover {
      color: #e67e22; }
    @include t {
      margin-bottom: 16px;
      font-size: 24px; }
    @include m {
      margin: 0;
      font-size: 20px; }
    @include a {
      font-size: 16px; } }
  &__list {
    display: flex;
    margin: 0 -25px;
    @include x {
      margin: 0 -10px; }
    @include m {
      flex-wrap: wrap; }
    @include a {
      display: block;
      margin: 0; } }
  &__section &__list {
    flex-wrap: wrap;
    @include m {
      margin-top: -10px; }
    @include a {
      margin-top: -5px; } }
  .element {
    flex: 0 0 calc(16.66% - 50px);
    width: calc(16.66% - 50px);
    margin: 0 25px;
    @include x {
      flex: 0 0 calc(16.66% - 20px);
      width: calc(16.66% - 20px);
      margin: 0 10px; }
    @include d {
      flex: 0 0 calc(20% - 20px);
      width: calc(20% - 20px); }
    @include t {
      flex: 0 0 calc(25% - 20px);
      width: calc(25% - 20px); }
    @include m {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin-top: 25px; }
    @include a {
      width: 100%;
      margin: 25px 0 0; } }
  &_top .element:nth-child(n+6) {
    @include d {
      display: none; }
    @include m {
      display: flex; } }
  &_top .element:nth-child(n+5) {
    @include t {
      display: none; }
    @include m {
      display: flex; } }
  &__section .element {
    margin-bottom: 40px;
    @include x {
      margin-bottom: 40px; }
    @include t {
      margin-bottom: 30px; }
    @include m {
      margin-bottom: 0; } } }

.element {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: $bl;
  @include m {
    position: relative;
    flex-direction: row;
    padding-bottom: 52px; }
  &__preview {
    position: relative;
    padding-bottom: 132%;
    font-size: 0;
    @include m {
      flex: 0 0 43%;
      width: 43%;
      padding: 0; } }
  &__pic {
    display: block;
    @include coverdiv;
    border-radius: 9px;
    object-fit: cover;
    @include m {
      position: static;
      height: auto; } }
  &__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 20px;
    @include t {
      padding-top: 15px; }
    @include m {
      flex: 0 0 57%;
      width: 57%;
      padding: 0 0 0 15px; } }
  &__title {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.15;
    color: $bl;
    transition: color .25s; }
  &__classes {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.15;
    font-weight: 300;
    text-transform: uppercase;
    transition: color .25s;
    @include t {
      margin-bottom: 15px; } }
  &:hover &__title,
  &:hover &__classes {
    color: $orange; }
  &__authors {
    margin: auto 0 20px;
    @include t {
      margin-bottom: 15px; }
    @include m {
      margin-bottom: 0; }
    li {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.15;
      @include nl {
        margin-bottom: 5px; } } }
  &__btn {
    min-width: 100%;
    @include m {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; } }
  &:hover &__btn {
    background: $orange; } }

.footer {
  &__row {
    position: relative;
    display: flex;
    padding: 88px 30px 83px 0;
    @include x {
      padding: 60px 0; }
    @include d {
      padding: 45px 0; }
    @include t {
      padding: 35px 0; }
    @include m {
      padding: 30px 0 20px; }
    @include a {
      display: block;
      text-align: center; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg, $bl 0, $bl 20%, transparent 20%, transparent 100%);
      background-size: 8px 1px; } }
  &__copyright {
    position: relative;
    margin-right: auto;
    padding-left: 30px;
    font-size: 16px;
    font-weight: 700;
    @include a {
      display: inline-block;
      margin: 0 0 25px; }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-55%);
      width: 19px;
      height: 11px;
      border-radius: 6px;
      background: $orange; } }
  &__links {
    display: flex;
    @include a {
      display: block; } }
  &__link {
    font-size: 13px;
    font-weight: 500;
    color: $bl;
    transition: color .25s;
    @include a {
      display: block;
      background: #3C4043;
      border-radius: 20px;
      font-size: 14px;
      line-height: 39px;
      color: $wh; }
    &:hover {
      color: $orange; }
    @include nl {
      margin-right: 62px;
      @include a {
        margin: 0 0 15px; } } } }

.breadcrumbs {
  margin-bottom: 20px;
  @include d {
    margin-bottom: 40px; }
  @include t {
    margin-bottom: 30px; }
  &__list {
    padding-left: 30px;
    @include x {
      padding: 0; } }
  &__item {
    position: relative;
    display: inline;
    font-size: 14px;
    font-weight: 700;
    color: $orange;
    @include m {
      font-size: 12px; }
    @include nf {
      padding-left: 25px;
      @include m {
        padding-left: 17px; }
      &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 0px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $grey; } }
    @include nl {
      margin-right: 16px;
      @include m {
        margin-right: 8px; } } }
  &__link {
    color: $grey;
    transition: color .25s;
    &:hover {
      color: $bl; } } }

.content {
  h2 {
    margin-bottom: 30px;
    font-size: 25px;
    line-height: (35/25);
    font-weight: 700;
    @include d {
      margin-bottom: 25px; }
    @include t {
      font-size: 20px; }
    @include m {
      margin-bottom: 20px;
      font-size: 18px; } }
  p {
    font-size: 16px;
    line-height: (22/16);
    @include m {
      font-size: 14px; }
    @include nf {
      margin-top: 30px;
      @include d {
        margin-top: 25px; }
      @include m {
        margin-top: 15px; } } }
  ul {
    list-style-type: disc;
    list-style-position: inside;
    li {
      @include nl {
        margin-bottom: 10px; } }
    @include nl {
      margin-bottom: 25px; } } }

.statistics {
  margin-bottom: 110px;
  @include d {
    margin-bottom: 90px; }
  @include t {
    position: relative;
    margin-bottom: 75px;
    padding-bottom: 50px; }
  @include m {
    margin-bottom: 45px;
    padding-bottom: 40px; } }

.description {
  margin-bottom: 68px;
  @include t {
    margin-bottom: 45px; }
  @include m {
    margin-bottom: 20px; }
  &__ctr {
    max-width: 1276px; }
  &__row {
    display: flex; }
  &__preview {
    flex-shrink: 0;
    width: 313px;
    font-size: 0;
    @include t {
      width: 230px; }
    @include m {
      width: 150px; } }
  &__pic {
    width: 100%; }
  &__wrap {
    flex-grow: 1;
    padding-left: 50px;
    @include d {
      padding-left: 30px; }
    @include t {
      display: flex;
      flex-direction: column; }
    @include m {
      padding-left: 10px; } }
  &_page &__wrap {
    display: flex;
    flex-direction: column; }
  &__head {
    display: flex;
    margin-bottom: 20px; }
  &__info {
    flex-grow: 1;
    padding-right: 50px;
    @include t {
      padding: 0; } }
  &__classes {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.1;
    @include m {
      font-size: 16px; } }
  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.1;
    @include d {
      margin-bottom: 5px;
      font-size: 28px; }
    @include m {
      font-size: 18px; } }
  &__share {
    flex-shrink: 0;
    @include t {
      display: flex;
      align-items: center;
      position: absolute;
      left: 20px;
      bottom: 0; } }
  &__share &__text {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 300;
    @include d {
      margin-bottom: 5px; }
    @include t {
      margin: 0 15px 0 0; } }
  &__social {
    display: flex;
    justify-content: flex-end; }
  &__link {
    font-size: 0;
    transition: transform .25s;
    .icon {
      font-size: 24px;
      fill: $bl; }
    @include nl {
      margin-right: 11px; } }
  &__options {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    padding: 37px 0;
    @include t {
      padding: 20px 0; }
    @include m {
      justify-content: flex-end;
      padding: 15px 0 0; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      background-image: linear-gradient(90deg, $bl 0, $bl 20%, transparent 20%, transparent 100%);
      background-size: 8px 1px;
      @include m {
        display: none; } }
    &:before {
      top: 0; }
    &:after {
      bottom: 0; } }
  &__option {
    color: #70757B;
    @include m {
      font-size: 12px; }
    strong {
      padding-left: 5px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.15;
      color: #3C4043;
      @include d {
        font-size: 18px; }
      @include m {
        font-size: 14px; } }
    @include nl {
      margin-bottom: 7px; } }
  &__box {
    margin-top: -148px;
    padding-left: 363px;
    @include d {
      margin-top: -165px;
      padding-left: 343px; }
    @include t {
      margin-top: 30px;
      padding: 0; }
    @include m {
      position: relative;
      margin-top: 20px;
      padding-top: 20px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-image: linear-gradient(90deg, $bl 0, $bl 20%, transparent 20%, transparent 100%);
        background-size: 8px 1px; } } }
  &__content {
    height: 86px;
    overflow: hidden;
    @include d {
      height: 103px; }
    @include t {
      height: 51px; }
    p {
      font-size: 14px;
      line-height: (18/14); } }
  &__btn {
    width: 194px;
    margin-top: 24px;
    @include t {
      margin-top: 20px; }
    @include m {
      width: 100%;
      margin-top: 15px; } } }

body:not(.touch-device) .description__link:hover {
  transform: translateY(-2px); }

.nav {
  &__search {
    display: flex;
    margin-bottom: 68px;
    padding: 0 100px;
    @include d {
      padding: 0; }
    @include t {
      margin-bottom: 45px; }
    @include m {
      display: block;
      margin-bottom: 20px; } }
  &__field {
    position: relative;
    flex-grow: 1; }
  &__input {
    width: 100%;
    height: 39px;
    padding: 2px 30px 0 40px;
    border-radius: 20px;
    border: 1px solid #2F8ECD;
    font-size: 13px;
    color: #3293D4;
    @include m {
      padding: 0 50px 0 15px; }
    @include placeholder {
      color: #3293D4; } }
  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 40px;
    padding-left: 10px;
    font-size: 0;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include m {
      left: auto;
      right: 0;
      padding: 0; }
    .icon {
      font-size: 14px;
      fill: $orange;
      transition: opacity .25s; }
    &:hover .icon {
      opacity: .7; } }
  &__btns {
    flex: 0 0 251px;
    width: 251px;
    padding-left: 57px;
    @include t {
      flex: 0 0 220px;
      width: 220px;
      padding-left: 30px; }
    @include m {
      display: none; } }
  &__btn {
    min-width: 100%; }
  &__item {
    overflow: hidden;
    border-radius: 31px;
    @include m {
      border-radius: 22px; } }
  &__container > &__item {
    margin-top: 25px;
    @include d {
      margin-top: 20px; }
    @include m {
      margin-top: 15px; } }
  &__container > &__item &__item {
    margin-top: 20px;
    @include d {
      margin-top: 15px; } }
  &__container > &__item &__item &__item {
    margin-top: 20px;
    @include d {
      margin-top: 15px; } }
  &__head {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 15px 65px 15px 30px;
    background: #2F8ECD;
    font-size: 18px;
    line-height: 1.15;
    font-weight: 700;
    cursor: pointer;
    color: $wh;
    transition: background .3s;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include t {
      min-height: 52px;
      padding-left: 25px; }
    @include m {
      min-height: 44px;
      padding: 10px 40px 10px 15px;
      font-size: 15px; } }
  &__container > &__item &__item &__head {
    padding-left: 20px;
    font-weight: 500;
    @include t {
      padding-left: 20px; }
    @include m {
      padding-left: 10px; } }
  &__container > &__item &__item &__body {
    padding-left: 25px;
    padding-right: 25px;
    background: lighten(#D5EFFA, 5);
    @include t {
      padding-left: 20px;
      padding-right: 20px;
      background: #D5EFFA; }
    @include m {
      padding-left: 10px;
      padding-right: 10px; } }
  &__container > &__item &__item &__item &__head {
    padding-left: 20px;
    font-weight: 400;
    color: $wh;
    @include m {
      padding-left: 10px; } }
  &__container > &__item &__item &__item &__body {
    padding-left: 20px;
    padding-right: 20px;
    background: lighten(#D5EFFA, 2);
    @include m {
      padding-left: 10px;
      padding-right: 10px; } }
  &__container > &__item &__item &__item &__item &__body {
    background: darken(#D5EFFA, 1); }
  &__counter {
    flex-shrink: 0;
    margin-left: 23px;
    padding-top: 4px;
    font-size: 14px;
    font-weight: 300;
    color: $wh;
    @include m {
      margin-left: 10px; } }
  &__arrow {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-size: 0;
    transition: transform .25s;
    @include m {
      right: 15px; }
    .icon {
      font-size: 10px;
      fill: $wh; } }
  &__item.show > &__head &__arrow {
    transform: translateY(-50%) rotate(180deg); }
  &__body {
    display: none;
    padding: 0 30px 20px;
    border: 1px solid #2F8ECD;
    border-radius: 0 0 32px 32px;
    @include t {
      padding: 0 20px 15px;
      border-radius: 0 0 27px 27px; }
    @include m {
      padding: 0 10px 10px;
      border-radius: 0 0 23px 23px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    @include t {
      justify-content: center;
      margin: 0 -5px; }
    &.line {
      display: block;
      margin: 0; } }
  &__link {
    display: block;
    flex-shrink: 0;
    width: 55px;
    height: 55px;
    margin: 10px 10px 0;
    padding-top: 3px;
    border-radius: 50%;
    text-align: center;
    font-size: 16px;
    line-height: 52px;
    font-weight: 700;
    color: $bl;
    transition: all .25s;
    @include t {
      width: 45px;
      height: 45px;
      margin: 10px 5px 0;
      line-height: 42px;
      background: $wh;
      font-size: 15px; }
    @include m {
      width: 38px;
      height: 38px;
      margin: 10px 3px 0;
      font-size: 13px;
      line-height: 35px; } }
  &__list.line &__link {
    width: 100%;
    height: auto;
    margin: 10px 0 0;
    padding: 11px 30px 9px;
    border-radius: 25px;
    text-align: left;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    @include d {
      padding: 10px 20px 8px; }
    @include t {
      background: none;
      margin: 10px 0 0;
      padding: 10px 0 8px; }
    @include m {
      margin: 10px 0 0;
      padding: 5px;
      font-size: 14px; } } }

body:not(.touch-device) .nav__link:hover {
  background: $orange;
  color: $wh; }

.page {
  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 76px;
    margin-bottom: 38px;
    padding: 12px 30px 10px;
    border-radius: 38px 38px 0 0;
    background: #D5EFFA;
    font-size: 20px;
    font-weight: 700;
    color: $bl;
    @include d {
      min-height: 66px;
      margin-bottom: 30px;
      border-radius: 33px 33px 0 0; }
    @include t {
      justify-content: center;
      min-height: 56px;
      margin-bottom: 20px;
      border-radius: 28px 28px 0 0; }
    @include m {
      min-height: 44px;
      padding: 7px 15px 5px;
      font-size: 16px; } }
  &__wrap {
    position: relative; }
  &__preview {
    display: block;
    max-width: 830px;
    margin: 0 auto;
    font-size: 0;
    @include d {
      max-width: 730px; } }
  &__pic {
    width: 100%; }
  &__control {
    @include t {
      display: flex;
      justify-content: center;
      margin: 20px -10px 0; } }
  &__link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 39px;
    border-radius: 20px;
    background: #2F8ECD;
    font-size: 0;
    transition: background .25s;
    @include t {
      position: static;
      transform: translateY(0);
      flex: calc(50% - 20px);
      width: calc(50% - 20px);
      margin: 0 10px; }
    .icon {
      font-size: 10px;
      fill: $wh;
      @include t {
        font-size: 14px; }
      @include m {
        font-size: 12px; } }
    &:hover {
      background: $orange; }
    &_left {
      left: 100px;
      @include x {
        left: 30px; }
      @include d {
        left: 0; } }
    &_right {
      right: 100px;
      @include x {
        right: 30px; }
      @include d {
        right: 0; } } } }

.directory {
  margin-bottom: 25px;
  padding-top: 30px;
  @include x {
    padding: 0; }
  @include m {
    margin-bottom: 45px; }
  &__row {
    display: flex;
    @include t {
      display: block; } }
  &__sidebar {
    position: relative;
    flex-shrink: 0;
    width: 285px;
    padding-right: 11px;
    @include d {
      width: 240px; }
    @include t {
      width: 100%;
      margin-bottom: 20px;
      padding: 0; }
    @include m {
      margin-bottom: 15px; }
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      right: 0;
      bottom: 40px;
      width: 11px;
      background: $orange;
      border-radius: 6px;
      @include d {
        bottom: 30px; }
      @include t {
        display: none; } } }
  &__title {
    margin-bottom: 25px;
    @include d {
      font-size: 30px; }
    @include t {
      margin: 0; } }
  &__menu {
    margin-right: -1px;
    @include t {
      display: none; } }
  &__link {
    position: relative;
    display: block;
    padding: 0 30px;
    line-height: 39px;
    border-radius: 20px 0 0 20px;
    font-size: 16px;
    font-weight: 700;
    color: $bl;
    transition: all .25s;
    @include d {
      padding: 0 20px; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      right: 0;
      z-index: -1;
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% 100%;
      opacity: 0;
      pointer-events: none;
      transition: opacity .25s; }
    &:before {
      bottom: calc(100% - 1px);
      background-image: url('/build/image/subtraction-1.png'); }
    &:after {
      top: calc(100% - 1px);
      background-image: url('/build/image/subtraction-2.png'); }
    &.active {
      &:before,
      &:after {
        opacity: 1; } }
    @include nl {
      margin-bottom: 4px; } }
  &__sidebar &__menu &__link.active {
    background: $orange;
    color: $wh; }
  &__select.nice-select {
    display: none;
    margin-bottom: 30px;
    border-color: $orange;
    color: $orange;
    &:before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS45NTciIGhlaWdodD0iOS4xMjIiIHZpZXdCb3g9IjAgMCAxNS45NTcgOS4xMjIiPiAgPHBhdGggaWQ9Ikljb25faW9uaWMtaW9zLWFycm93LWJhY2siIGRhdGEtbmFtZT0iSWNvbiBpb25pYy1pb3MtYXJyb3ctYmFjayIgZD0iTTIuNzUsNy45NzYsOC43ODgsMS45NDNBMS4xNCwxLjE0LDAsMCwwLDcuMTcyLjMzM0wuMzMyLDcuMTY5QTEuMTM4LDEuMTM4LDAsMCwwLC4zLDguNzQxbDYuODY5LDYuODgzYTEuMTQsMS4xNCwwLDEsMCwxLjYxNS0xLjYxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCA5LjEyMikgcm90YXRlKC05MCkiIGZpbGw9IiNlNjdlMjIiLz48L3N2Zz4=); }
    &.open {
      background: $orange; }
    .list {
      border-color: $orange; }
    @include t {
      display: block; } }
  &__container {
    flex-grow: 1;
    padding: 6px 130px 0 70px;
    @include x {
      padding: 6px 0 0 30px; }
    @include t {
      padding: 0; } }
  &__section {
    @include nl {
      margin-bottom: 25px;
      @include d {
        margin-bottom: 15px; }
      @include t {
        margin-bottom: 20px; }
      @include m {
        margin-bottom: 35px; } } }
  &__stage {
    display: inline-block;
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: 300;
    color: #2f3133;
    &:hover {
      color: #e67e22; }
    @include d {
      margin-bottom: 16px;
      font-size: 24px; }
    @include m {
      margin: 0;
      font-size: 20px; }
    @include a {
      font-size: 16px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
    @include d {
      margin: 0 -10px; }
    @include m {
      margin-top: -10px; }
    @include a {
      display: block;
      margin: -10px 0 0; } }
  .element {
    flex: 0 0 calc(25% - 50px);
    width: calc(25% - 50px);
    margin: 0 25px 40px;
    @include d {
      flex: 0 0 calc(25% - 20px);
      width: calc(25% - 20px);
      margin: 0 10px 30px; }
    @include m {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin: 25px 10px 0; }
    @include a {
      width: 100%;
      margin: 25px 0 0; } } }

body:not(.touch-device) .directory__link:hover {
  color: $orange; }

.result {
  margin-bottom: 25px;
  padding-top: 20px;
  @include d {
    padding: 0; }
  @include m {
    margin-bottom: 45px; }
  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 65px;
    @include d {
      display: block;
      margin-bottom: 40px; }
    @include t {
      margin-bottom: 30px; }
    @include m {
      margin-bottom: 20px; } }
  &__title {
    margin-right: auto;
    @include d {
      margin: 0 0 10px;
      font-size: 30px; }
    &:before {
      top: 17px;
      transform: translateY(0);
      @include d {
        top: 13px; }
      @include t {
        top: 11px; }
      @include m {
        top: 6px; } } }
  &__counter {
    flex-shrink: 0;
    margin-left: 50px;
    font-weight: 700;
    color: $grey;
    @include d {
      margin: 0;
      padding-left: 20px; }
    @include m {
      padding-left: 15px; } }
  &__section {
    @include nl {
      margin-bottom: 25px;
      @include d {
        margin-bottom: 15px; }
      @include m {
        margin-bottom: 35px; } } }
  &__stage {
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: 300;
    @include t {
      margin-bottom: 16px;
      font-size: 24px; }
    @include m {
      margin: 0;
      font-size: 20px; }
    @include a {
      font-size: 16px; } }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;
    @include x {
      margin: 0 -10px; }
    @include m {
      flex-wrap: wrap;
      margin-top: -10px; }
    @include a {
      display: block;
      margin: -10px 0 0; } }
  .element {
    flex: 0 0 calc(16.66% - 50px);
    width: calc(16.66% - 50px);
    margin: 0 25px 40px;
    @include x {
      flex: 0 0 calc(16.66% - 20px);
      width: calc(16.66% - 20px);
      margin: 0 10px 40px; }
    @include d {
      flex: 0 0 calc(20% - 20px);
      width: calc(20% - 20px); }
    @include t {
      flex: 0 0 calc(25% - 20px);
      width: calc(25% - 20px); }
    @include m {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
      margin: 25px 10px 0; }
    @include a {
      width: 100%;
      margin: 25px 0 0; } } }

.company {
  margin-bottom: 100px;
  padding-top: 20px;
  @include m {
    margin-bottom: 50px;
    padding: 0; }
  &__title {
    margin-bottom: 50px;
    @include t {
      margin-bottom: 35px; }
    @include m {
      margin-bottom: 25px; } }
  &__content {
    max-width: 1216px;
    margin: 0 auto; } }

.mistake {
  text-align: center;
  &_search {
    padding: 52px 0 100px;
    @include d {
      padding: 30px 0 80px; }
    @include m {
      padding: 0 0 60px; } }
  &_number {
    padding: 120px 0 150px;
    @include d {
      padding: 90px 0 100px; }
    @include m {
      padding: 40px 0 60px; }
    @include s {
      padding-top: 60px; } }
  &__preview {
    margin-bottom: 90px;
    font-size: 0;
    @include d {
      margin-bottom: 50px; }
    @include m {
      margin-bottom: 35px; } }
  &_search &__pic {
    @include d {
      width: 450px; }
    @include t {
      width: 400px; }
    @include m {
      width: 300px; } }
  &_number &__pic {
    @include m {
      width: 300px; } }
  &__info {
    margin-bottom: 40px;
    font-size: 35px;
    line-height: (39/35);
    font-weight: 700;
    @include d {
      font-size: 30px; }
    @include t {
      font-size: 24px; }
    @include m {
      margin-bottom: 25px;
      font-size: 18px; } }
  &__number {
    position: relative;
    z-index: 2;
    font-size: 100px;
    font-weight: 700;
    line-height: 1;
    @include t {
      font-size: 80px; }
    @include m {
      font-size: 54px; } }
  &_number &__preview {
    margin-top: -150px;
    @include m {
      margin-top: -60px; } } }

.fancybox-button--zoom {
  display: none!important; }

.burger {
  position: relative;
  z-index: 30;
  display: none;
  width: 24px;
  height: 16px;
  text-align: center;
  transition: background .3s;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  @include m {
    display: inline-block; }
  &:before,
  &:after,
  span {
    display: block;
    width: 24px;
    height: 2px;
    transition: transform .3s, background .3s; }
  &:before,
  &:after {
    content: '';
    margin: 0 auto;
    background: $orange; }
  span {
    margin: 5px auto;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgMjQgMiI+ICA8ZyBpZD0iR3JvdXBfMzI4IiBkYXRhLW5hbWU9Ikdyb3VwIDMyOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyIC0xOSkiPiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzE0MSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMTQxIiB3aWR0aD0iMTYiIGhlaWdodD0iMiIgcng9IjEiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEyIDE5KSIgZmlsbD0iI2U2N2UyMiIvPiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzE0NSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgMTQ1IiB3aWR0aD0iNCIgaGVpZ2h0PSIyIiByeD0iMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzIgMTkpIiBmaWxsPSIjZTY3ZTIyIi8+ICA8L2c+PC9zdmc+) no-repeat 0 0 / 100% 100%; }
  &.active {
    background: transparent;
    &:before,
    &:after,
    span {
      background: $bl; }
    &:before {
      transform: translateY(7px) rotate(45deg); }
    span {
      transform: rotate(-45deg); }
    &:after {
      transform: translateY(-7px) rotate(45deg); } } }

